import mushroom from 'mushroomjs';

mushroom._defineAsyncResource({name:"mushroom_user",actions:{"findMany":{"clientCache":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
mushroom._defineAsyncResource({name:"application",actions:{"findMany":{"clientCache":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
mushroom._defineAsyncResource({name:"survey",actions:{"findMany":{"clientCache":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{},"_raw_http_method_generateHashCode":{},"_raw_http_method_validateHashCode":{}},views:{}});
mushroom._defineAsyncResource({name:"survey_item",actions:{"findMany":{"clientCache":true},"findById":{"clientCache":true},"createOne":{},"createMany":{},"updatePartially":{},"deleteOne":{}},views:{}});
mushroom._defineAsyncResource({name:"survey_callback",actions:{"findMany":{"clientCache":false},"findById":{"clientCache":false}},views:{}});
mushroom._defineAsyncResource({name:"survey_result",actions:{"findMany":{"clientCache":true},"findById":{"clientCache":true},"createOne":{}, "deleteOne":{}},views:{"rptSurveyResult":{},"rptSurveyResultText":{}}});
mushroom._defineAsyncResource({name:"prefill",actions:{"findById":{"clientCache":true},"createOne":{}},views:{}});

function toQueryString(obj, path) {
    if (obj === null || obj === undefined || typeof obj == "function")
        return "";

    if (typeof obj == "string" || typeof obj == "number" || typeof obj == "boolean")
        return obj;

    if (Array.isArray(obj))
        return obj.map(function (item) {
            return toQueryString(item, path);
        }).join(",");

    var arr = [];
    for (var m in obj) {
        if (!obj.hasOwnProperty(m))
            continue;

        var key = (path ? path : "") + m;
        arr.push(key + "=" + toQueryString(obj[m], key));
    }
    return arr.join("&");
}

mushroom.survey.generateHashCodeAsync = mushroom.__createAsyncRestFunction({
  name: "survey.generateHashCodeAsync", // Identify your method
  method: "POST", // GET if your request doesn't have body otherwise POST
  headers: {
    "X-HTTP-Method-Override": "generateHashCode" // real method
  },
  blankBody: false, // true if your request doesn't have body
  url: mushroom._fnGetRootApiUrl() + "surveys/generateHashCode"
});

mushroom.survey_result.confirmAsync = function (body, params) {
    let fn = mushroom.__createAsyncRestFunction({
        name: "survey_results.confirmAsync", // Identify your method
        method: "POST", // GET if your request doesn't have body otherwise POST
        headers: {
            "X-HTTP-Method-Override": "confirm" // real method
        },
        blankBody: false, // true if your request doesn't have body
        url: mushroom._fnGetRootApiUrl() + "survey_results/confirm?" + toQueryString(params)
    });
    return fn(body)
}

mushroom.survey_result.partialUpdateAsync = function (body, params) {
    let fn = mushroom.__createAsyncRestFunction({
        name: "survey_results.partialUpdate", // Identify your method
        method: "PATCH", // GET if your request doesn't have body otherwise POST
        headers: {
            "SurveyToken": params.surveyToken
        },
        blankBody: false, // true if your request doesn't have body
        url: mushroom._fnGetRootApiUrl() + "survey_results/" + body.id
    });
    return fn(body)
}

mushroom.$using(window.MyConfig.hostApi);
export default mushroom;
